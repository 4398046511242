import React from "react"
import { Link } from "gatsby"
import Image from "@/components/general/Image"
// import Layout from "@/components/layout"
// import Image from "@/components/image"
import SEO from "@/components/seo"
import Opener from "@/components/general/Opener"
import Sizing from "@/components/general/Sizing"

import HungerGraph from "@/components/graphs/HungerGraph.es"
import ColorChangeBG from "@/components/general/ColorChangeBG"
import Spacing from "@/components/general/Spacing"
import Signup from "@/components/general/Signup.es"
import Dropcap from "@/components/general/Dropcap"
import FooterNav from "@/components/general/FooterNav"

import Motif from "@/components/general/Motif"
import Footer from "@/components/general/Footer.es"
import Share from "@/components/general/Share.es"

import openerText from "@/images/hunger/opener-text_spanish.png"
import openerTextSml from "@/images/hunger/opener-text-sml_spanish.png"

import dcm from "@/images/hunger/dcm_spanish.png"
import dcw from "@/images/hunger/dcw_spanish.png"
import shareImg from "@/images/og/og_hunger_spanish.jpg"

import SignupPopup from "@/components/general/SignupPopup.es"

const HungerPage = ({pageContext}) => {
    return (
        <>
            <SEO
                page="/es/hunger"
                title="Free Markets Destroy | Aprenda cómo los mercados libres destruyen el hambre"
                description="La mayoría de la gente está de acuerdo en que los mercados son excelentes para fabricar cosas, pero ¿pueden los mercados resolver los problemas más difíciles de la humanidad?"
                img={shareImg}
                lang="es"
            />

            <Opener
                pageName="hunger"
                titles={[
                    { img: openerText, size: 14 },
                    { img: openerTextSml, size: 22 },
                ]}
            />

            {
                // ======================================= SECTION 1 =======================================
            }

            <ColorChangeBG
                from={{ r: 255, g: 255, b: 255 }}
                to={{ r: 183, g: 131, b: 185 }}
            >
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginLeft: 2,
                                marginBottom: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 4,
                                width: 9,
                                marginBottom: 0,
                            },
                        },
                    ]}
                >
                    <div className="copy--lead">
                        <Dropcap img={dcm}>L</Dropcap>a mayoría de la gente está de acuerdo en que los mercados son excelentes para crear cosas. Botas de montaña, cascos de bicicleta, coches eléctricos y iPads: casi todos los productos que hacen que nuestras vidas sean más seguras, fáciles y divertidas son el resultado de las fuerzas del mercado. Y si bien es maravilloso tener acceso a herramientas tan increíbles, incluso una innovación tan revolucionaria como la lavadora sigue siendo, en algún nivel, solo más cosas. Si eres escéptico sobre los mercados libres, entonces es justo preguntar si pueden hacer más que llenar nuestras tiendas y hogares con juguetes. ¿Pueden los mercados resolver los problemas más difíciles de la humanidad?
                    </div>

                    <p className="copy">
                        Es difícil imaginar un problema humano más difícil y más persistente que el hambre. Durante miles de años, el hambre fue la norma para la mayoría de las personas en el planeta, e incluso los reyes y sultanes más ricos eran vulnerables a la hambruna.
                    </p>
                    <p className="copy">
                        Pero a partir de la década de 1700, todo cambió gracias en parte a una de las mayores innovaciones en la historia humana: la especialización. La especialización es la división del trabajo en nichos estrechos y "especializados". Es la razón por la que puedes poner comida en tu mesa cortando cabello o escribiendo código para videojuegos. Y ese es el producto de los mercados libres.
                    </p>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "none",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 3,
                                marginTop: 1,
                                width: 2,
                            },
                        },
                    ]}
                >
                    <Motif page="hunger" id="motif_f" movement={0.1} />
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "none",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 6,
                                marginTop: 4,
                                width: 2,
                            },
                        },
                    ]}
                >
                    <Motif page="hunger" id="cover_b" movement={0.2} />
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 23,
                                marginLeft: 0.5,
                                marginBottom: 3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginTop: 2,
                                marginBottom: 0,
                            },
                        },
                    ]}
                >
                    <Image
                        src="hunger_tractor"
                        width={{ width: "100%" }}
                    ></Image>
                </Sizing>

                <div style={{ float: "left", clear: "left" }}>
                    <SignupPopup></SignupPopup>
                </div>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 18,
                                marginLeft: 2,

                                marginTop: 3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 2,
                                width: 4,
                                minWidth: "350px",
                                marginTop: 2,
                                // marginBottom: 1,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <Signup color={"#ec5843"}></Signup>
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginRight: 2,
                                marginTop: 4,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 2,
                                width: 8,
                                marginTop: 2,
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                marginRight: 4,
                                width: 8,
                                marginTop: 2,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <div className="copy">
                        <Dropcap img={dcw}>S</Dropcap>
                        in especialización, todo el mundo necesita cultivar su propia comida, coser su propia ropa y construir sus propias casas. Una vez que comenzamos a especializarnos, los trabajadores y empresarios pueden enfocarse en tareas específicas, como cultivar trigo. Su experiencia les permite producir un excedente, que comercializan con fabricantes de ropa y constructores de casas. Muy rápido, todo el mundo se vuelve mejor. Y debido a que el agricultor necesita al constructor y viceversa, es más probable que se traten unos a otros con tolerancia y respeto.
                    </div>
                    <p className="copy">
                        Y a medida que los agricultores se especializaban—uno cultivando maíz, otro cultivando manzanas—comenzaron a experimentar con nuevas herramientas y técnicas: rotación de cultivos, mejoramiento selectivo, fertilización y muchas más. Poco a poco, una innovación a la vez, los agricultores lograron hacer su trabajo más eficiente y más rentable.
                    </p>
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginRight: 2,
                                marginTop: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 3,
                                width: 14,
                                marginTop: 2,
                            },
                        },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 1,
                                    top: -1,
                                    right: -1,
                                },
                            },
                        ]}
                    >
                        <Motif page="hunger" id="motif_a" movement={-0.1} />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 1,
                                    top: 3,
                                    left: -2,
                                },
                            },
                        ]}
                    >
                        <Motif page="hunger" id="motif_c" movement={0.2} />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 1.5,
                                    bottom: -2,
                                    left: 1,
                                },
                            },
                        ]}
                    >
                        <Motif page="hunger" id="motif_e" movement={-0.1} />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 3,
                                    bottom: -2,
                                    right: -2,
                                },
                            },
                        ]}
                    >
                        <Motif page="hunger" id="cover_h" movement={0.1} />
                    </Sizing>

                    <Image src="hunger_pizza" width={{ width: "100%" }}></Image>
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative", zIndex: 4 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginRight: 2,
                                marginTop: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 4,
                                width: 8,

                                marginTop: 2,
                                marginBottom: 2,
                            },
                        },
                    ]}
                >
                    <p className="copy">
                        Desde la época de la Revolución Americana hasta hoy, el suministro per cápita de calorías en Occidente ha aumentado más del 70 por ciento. La imagen es aún más impresionante cuando se acerca a los sectores más pobres de la sociedad. Hace doscientos años, el 20% más pobre de las personas en Inglaterra y Francia carecía de la energía para un trabajo sostenido. En otras palabras, los más pobres - los que necesitaban desesperadamente oportunidades-no podían trabajar porque no tenían suficiente para comer. Esa es la definición misma de un círculo vicioso.
                    </p>

                    <Sizing
                        styles={{ position: "absolute", zIndex: 2 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 2,
                                    bottom: -2,
                                    right: -2,
                                },
                            },
                        ]}
                    >
                        <Motif
                            page="hunger"
                            id="motif_f"
                            movement={-0.1}
                            rotation={-0.1}
                        />
                    </Sizing>
                </Sizing>
            </ColorChangeBG>

            <HungerGraph></HungerGraph>

            <Sizing
                styles={{ position: "relative", zIndex: 4 }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            width: 24,
                            marginTop: 2,
                            marginBottom: 2,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginTop: 2,
                            marginBottom: 2,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        },
                    },
                ]}
            >
                <Sizing
                    styles={{ position: "absolute", zIndex: 2 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 2,
                                top: -3,
                                left: 4,
                            },
                        },
                    ]}
                >
                    <Motif page="hunger" id="motif_d" movement={0.1} />
                </Sizing>
                <Sizing
                    styles={{ flexGrow: 0, flexShrink: 0 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginLeft: 2,
                                marginBottom: 2,
                                float: "left",
                                paddingLeft: 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                width: "auto",
                                marginLeft: 0,
                                marginBottom: 0,
                                float: "none",

                                paddingLeft: 3,
                                flexBasis: 7,
                            },
                        },
                    ]}
                >
                    <p className="copy">
                        Por supuesto, el hambre sigue siendo un desafío para millones de personas en todo el mundo, pero el progreso que hemos logrado en solo unos pocos cientos de años es nada menos que asombroso. De 1800 a 2010, el precio del trigo, el alimento básico más omnipresente de la humanidad, cayó más del 88%. Durante el mismo período, la proporción de la población mundial que vive en la llamada "pobreza absoluta", sobreviviendo a la sombra del hambre, ha caído del 94% a solo 9.6%.
                    </p>
                </Sizing>

                <Sizing
                    styles={{ flexGrow: 0, flexShrink: 0 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 22,
                                marginLeft: 1,
                                marginBottom: 4,
                                float: "left",
                                paddingLeft: 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                width: "auto",
                                marginLeft: 0,
                                marginBottom: 0,
                                float: "none",

                                paddingLeft: 2,
                                flexBasis: 10,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    {" "}
                    <Image
                        src={"hunger_isle"}
                        style={{ width: "100%" }}
                    ></Image>{" "}
                </Sizing>
            </Sizing>

            <Spacing space="0" />
            <Sizing
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: 20,
                            maxWidth: "300px",
                            height: "50px",
                            marginTop: 2,
                            paddingBottom: 5,
                            position: "relative",
                            display: "block",
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            display: "none",
                        },
                    },
                ]}
            >
                <Share></Share>
            </Sizing>

            <Spacing space="0" />
            <FooterNav next={"rents"} previous={"disease"} langKey={pageContext.langKey}/>
            <Footer></Footer>
        </>
    )
}

export default HungerPage
